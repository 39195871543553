.gplocator {
  text-align: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}

.gplocator-header {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.introduction {
  text-align: center;
  margin: 0 auto 20px;
  padding: auto;
  max-width: 800px;
  line-height: 1.5;
}
.instructions-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 800px; 
  text-align: left; /* Align the text to the left */
  align-items: center;
}

.instructions-container ol {
  list-style-type: none; /* Remove default numbering */
  padding-left: 0;
}

.instructions-container ol li {
  margin-bottom: 15px;
}

.instructions-container strong {
  font-size: 1.1em;
}

.map {
  width: 100%;
  height: 700px; 
  border: 2px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}


.map-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.map-controls button {
  padding: 15px;
  font-size: 18px;
  width: 150px;
}

.map-controls button:hover {
  background:#f7959d;
  color: white;
}

.controls {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.search-bar-container {
  position: relative;
  width: 40%;
}

#autocomplete-input {
  width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 50px;
}

.clear-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.controls button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(135deg, #f7959d, #fbd3e1); 
  color: white;
  transition: background-color 0.3s ease;
  min-width: 150px;
  height: 50px;
}

.controls button:hover {
  background: linear-gradient(135deg, #fbd3e1, #f7a1c4); 
  color: white;
}



