.progress-bar-container {
    max-width: 900px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 10px;
    margin: 10px 0;
    overflow: hidden;
    margin-top: 30px;
  }
  
  .progress-bar {
    height: 100%;
    background: linear-gradient(to right, #00CFFF, #447beb);
    text-align: center;
    line-height: 20px;
    color: white;
    font-weight: bold;
    transition: width 0.4s ease;
    border-radius: 10px 0 0 10px;
  }
  