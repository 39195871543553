.insight {
  margin: 50px;
  margin-top: 60px;


}
.insight-header{
  text-align: center;
    align-items: center;
    justify-content: center;
    margin: 20px;
    margin-top: 70px;
}
.dropdown-container-country { 
  display: inline-block;
  margin: 10px 0;
}

.dropdown-container-age { 
  display: inline-block;
  margin: 10px 0;
}

.dropdown-container-country label {
  font-weight: bold;
  margin-left: 0px;
}

.dropdown-container-age label {
  font-weight: bold;
  margin-left: 1040px;
}

.chart-wrapper {
  display: flex; /* Aligns child elements side by side */
  gap: 30px; /* Adds space between the bar chart and the top causes section */
}

.chart-container {
  flex: 2; /* Allows it to grow more */
  margin-bottom: 20px;
}

.top-causes {
  flex: 1; /* Allows it to grow less compared to the bar chart */
  display: flex; 
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  align-items: flex-start; /* Align items to the left */
  padding: 10px;
}

.top-cause {
  font-size: 18px; 
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

.top-cause-1 {
  color: crimson;
  font-size: 30px; 
}

.top-cause-2 {
  color: tomato;
  font-size: 24px;
}

.top-cause-3 {
  color: sandybrown;
}

/* Updated the canvas size for smaller charts */
#bar-chart, #line-chart {
  width: 70%; /* Adjust to 70% of the container width */
  height: 200px; /* Reduce height to 200px */
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.chart-container canvas {
  width: 100%; /* Canvas will take full width of the container */
  height: 200px; /* Reduce height */
}

.top-cause-desc {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description-section {
  background-color: #f9ecef;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.description-section h2, .description-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.description-section p, .description-section ul {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.description-section ul {
  list-style-type: none;
  padding-left: 20px;
}

.description-section li {
  margin-bottom: 5px;
}

.description-section li ul {
  list-style-type: disc;
}