
.navbar {
  display: flex;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.pink-bar {
  display: flex;
  background-color: #fefcfc;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0px;
  width: 100%;
  height: 40px;
  z-index: 1000; /* Ensure it stays above other content */
  margin-left: 10px;
}
.pink-bar p{
  font-size: 11px;
}

/* Style for the Google Translate widget */
#google_translate_element {
  display: inline-block;
  text-align: center;
}

.navbar-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 80px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  padding: 0 15px;
  font-size: 16px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  position: relative;
}

/* Active link underline */
.nav-links a.active::after {
  content: '';
  display: block;
  height: 2px; /* Thin blue underline */
  background-color: #007bff; /* Blue underline for active links */
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
}

/* Hover effect for all links */
.nav-links a:hover::after {
  content: '';
  display: block;
  height: 2px; /* Thin blue underline */
  background-color: #007bff;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
}

/* Dropdown Menu Styling */
.dropdown {
  position: relative;
}

.drop-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #333;
}

.dropdown-toggle svg {
  margin-left: 8px; /* Space between text and arrow */
}

/* Dropdown Menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%; 
  left: 10px;
  background-color: #fff;
  min-width: 250px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 5px;
}

/* Show dropdown when hovering over MediSupport Hub */
.dropdown.has-dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  padding: 10px;
}

.dropdown-menu li a {
  font-size: 18px;
  color: #333;
  text-decoration: none;
  display: block;
  position: relative;
}

/* Underline for Medical Insight in dropdown */
.dropdown-menu li a:hover::after {
  content: '';
  display: block;
  height: 2px;
  background-color: #007bff;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
}

/* Mobile View */
.hamburger-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: #333;
}

.nav-menu {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
  }

  .hamburger-icon {
    display: block;
  }

  .nav-links li {
    padding: 15px;
    text-align: center;
  }

  .nav-links a {
    font-size: 18px;
  }

  /* Hide dropdown chevron in mobile view */
  .drop-toggle svg {
    display: none;
  }
}

